
<template v-if="daten">
  <div v-if="zeigeSuchfelder" class="ci-searchbar">
    <v-form  ref="search" @submit.prevent="submitSearch()" v-model="valid">
    <v-container>
      <v-row>
        <v-col v-if="suchItem.sfld" cols="12" sm="6" md="4">
          <v-text-field single-line outlined v-model="suchFormular.sfld" filled hide-details></v-text-field>
        </v-col>
        <v-col v-if="suchItem.svon" cols="12" sm="6" md="2">
          <v-text-field label="von" type="date" v-model="suchFormular.svon" outlined filled hide-details></v-text-field>
        </v-col>
        <v-col v-if="suchItem.sbis"  cols="12" sm="6" md="2">
          <v-text-field label="bis" type="date" v-model="suchFormular.sbis" outlined filled hide-details></v-text-field>
        </v-col>
        <v-col v-if="suchItem.stat" cols="12" sm="6" md="2">
           <v-select   filled  hide-details       
               v-model="suchFormular.stat"    
                :items="suchItem.statuswerte"
                label="Status"
                item-text="bezeichnung"
                item-value="id"
                outlined
                @change="submitSearch()"
                ></v-select>
        </v-col>
        <v-col  cols="12" sm="12" md="4" lg="2">
          <span style=" white-space: nowrap;">
          <v-btn type="submit" >{{ suchItem.text }}</v-btn>
          &nbsp;
          <v-btn  @click="reset()" >Reset</v-btn>
          </span>
        </v-col>
      </v-row>
    </v-container>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { POST_SEARCH } from "@/store/actions.type";
import { RESET_SEARCH } from "@/store/mutations.type";


export default {
  data: () => ({
    valid: true
  }),
  name: "QSearchbar",
  computed: {
    /*
    ...mapState({
        suchFormular: state => state.suchFormular,
    }),
    */
    ...mapGetters(["daten", "zeigeSuchfelder", "suchItem", "suchFormular"])
  },
   methods: {
    submitSearch() {
      if (this.$refs.search.validate()) {
        this.$store.dispatch(POST_SEARCH)
      }
    },
    reset() {
      this.$store.commit(RESET_SEARCH);
      this.submitSearch()
    }    
   }
};
</script>
