<template>
  <v-container>
    <v-row >
      <template v-for="(card, card_index) in attribute" cols="12">
    

        <template v-if="card.id && !card.oben ">
          <v-col
            :key="'card-1-' + card.id"
            v-if="(card.attributart === 11 || card.attributart === 12) && card.bezeichnung"
            cols="12"
          >
            <div>{{ card.bezeichnung }} </div>
          </v-col>
          <v-col
            cols="12"
            :sm="colsSM(card)"
            :lg="colsLG(card)"
            :card_index="card_index"
            :key="'card-2-' + card.id"
          >
            <template v-if="card.id && !card.oben && (card.attributart == 2 || card.attributart == 3)">
              <v-card  
                :color="(card.attributart == 3)?'transparent':''"
                :elevation="(card.attributart == 3)?'0':'1'">
                    <v-card-title v-if="card.bezeichnung" class="headline">{{ card.bezeichnung }}</v-card-title>             
                      <v-container>       
                    <Attribute :parent="card"></Attribute>
                      </v-container>
              </v-card>
            </template>   
            <template  v-else-if="card.id && !card.oben">
                <v-container>
                  <Attribut class="123" :item="card"></Attribut> 
                  <!-- <Attribut v-else-if="card.id && !card.oben" :item="card"></Attribut> -->
                </v-container>
            </template>   
          </v-col>
        </template>
      </template>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Attribute from "@/components/Attribute.vue";
import Attribut from "@/components/Attribut.vue";

export default {
  name: "QAttributbloecke",
  computed: {
    ...mapGetters(["daten", "attribute"])
  },
  components: {
    Attribute,
    Attribut
  }, 
  methods: {
  
      colsSM(item){

          if (item.attributart === 12) {
              return 6
          }
          return 12
      },
      colsLG(item){
          if (item.attributart === 25) {
              return 6
          }          

          if (item.attributart === 12) {
              return 4
          }
          if (item.attributart == 22 || item.attributart == 23) {
              return 12 //3
          }          
          return 12
      }
  }
};
</script>
