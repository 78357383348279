<template v-if="daten && daten.einzelsatz">
  <v-container class="ci-content" v-bind:class="{'ci-dynattr' : daten.einzelsatz.dynattr  }">
    <v-form v-if="daten.einzelsatz.update" ref="form" v-model="valid" lazy-validation>
      <Attributebloecke   class="ci-formular-felder" />
    </v-form>
    <Attributebloecke v-else  class="ci-anzeige-felder"/>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { POST_ATTRIBUTE } from "../store/actions.type";
import Attributebloecke from "@/components/Attributbloecke.vue";
import { bus } from '../main'


export default {
  name: "QEinzelsatz",
  data: () => ({
    valid: true
  }),
  computed: {
    ...mapGetters(["daten"])
  },
  components: {
    Attributebloecke
  },
  mounted() {
      this.$nextTick(function () {
      let options = {
        duration: 0
      }
      this.$vuetify.goTo(0, options)
      })

  },
  created (){
    bus.$on('einzelsatzSubmit', (navi) => {
      /*
      if (this.$refs && this.$refs.form && this.$refs.form.validate()) {
        this.$store.dispatch(POST_ATTRIBUTE, navi);
      }
      */
   //  console.log("TEST")
      this.validate(navi)
    })
  },
  beforeDestroy () {
    bus.$off('einzelsatzSubmit');
  },
  methods: {
    
    validate(navi) {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(POST_ATTRIBUTE, navi);
      }
    }
  }
};
</script>
