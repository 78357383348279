<template> 
  <span v-if="!item.ifwlattr">
  <span v-if="!item.update">

    <div class="ci-readonly-feld">
      <div v-if="item.attributart == 2">
        <div class="ci-bezeichnung ci-titel"> {{item.bezeichnung }}</div>
        <div v-if="item.hinweis" class="ci-hinweis"> {{item.hinweis }}</div>
      </div>
    <v-checkbox
   
      v-else-if="item.attributart === 4"
      v-model="item.value"
      :label="item.bezeichnung"
      :readonly="true"
      :hint="item.hinweis"
      :hide-details="(item.hinweis)?false:true"
      :persistent-hint="(item.hinweis)?true:false"    
    ></v-checkbox>
    <div v-else-if="item.attributart === 6">
      <div class="ci-bezeichnung  primary--text"> {{item.bezeichnung }}</div>
        <div class="ci-wert">
        <template v-if="item.text">
          <template v-for="(line, index) in  item.text.split('\n')"  :index="index" > {{line}}<br v-bind:key="item.id + '-line-' + index"  /></template>
        </template>
      </div>
      <div v-if="item.hinweis" class="ci-hinweis"> {{item.hinweis }}</div>
    </div>
    <div  v-else-if="item.attributart == 10">
      <div class="ci-bezeichnung  primary--text"> {{item.bezeichnung }}</div>
      <div  class="ci-wert ci-svg-elment">
        <div v-html="printElementSVG()"></div>
      </div>
    </div>     
    <v-input
      v-else-if="item.attributart == 11"
      @click="downloadFile()"
      :append-icon="(item.loesch_link)? 'delete': ''"
      @click:append="deleteFile()"
      prepend-icon="pdf"
    >{{item.original_name}}</v-input>    
    <v-card  v-else-if="item.attributart == 12">
      <v-img class="ci-mouse-over" :src="dataSrc"  contain height="200"  :max-width="item.weite"   @click="downloadFile()" ></v-img>
      <v-card-actions>
        <span class="body-2 font-weight-light">{{ item.original_name }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="rotateImage()"><v-icon>replay</v-icon></v-btn>
        <v-btn v-if="item.loesch_link" icon @click="deleteFile()"><v-icon>delete</v-icon></v-btn>
      </v-card-actions>
    </v-card>  
    <span v-else-if="item.attributart == 21">
      <div  class="ci-readonly-feld">
        <div class="ci-bezeichnung  primary--text"><v-btn @click="nav()"><v-icon>{{item.icon }}</v-icon>{{item.bezeichnung }}</v-btn></div>
      </div> 
    </span>        
    <span v-else-if="item.attributart == 25">
      <div  class="ci-readonly-feld">
          <div class="ci-bezeichnung  primary--text"> {{item.bezeichnung }}</div>
           <div class="ci-wert">
          <apexchart   type="line"  :options="item.data.chartOptions" :series="item.data.series"></apexchart>
          </div>
      </div> 
    </span>    
    <div v-else>
      <div class="ci-bezeichnung primary--text">{{item.bezeichnung }}</div>
      <div class="ci-wert">{{ wert() }}{{(item.einheit)? ' ' + item.einheit: ''}}</div>
      <div v-if="item.hinweis" class="ci-hinweis"> {{item.hinweis }}</div>
    </div>    
    </div>
  </span>
  <span v-else >
  <v-checkbox
    v-if="item.attributart === 4"
    v-model="item.value"
    :label="item.bezeichnung"
    :hint="item.hinweis"
    :placeholder="item.eingabehilfe"
    :suffix="item.einheit"
    :hide-details="(item.hinweis)?false:true"
    :persistent-hint="(item.hinweis)?true:false"
     @change="onItemChange()"
  ></v-checkbox>
  <v-textarea
    v-else-if="item.attributart === 6"
    :label="item.bezeichnung"
    :value="item.text"
    :filled="item.update"
    v-model="item.value"
    :auto-grow="true"
    :rules="rules()"
    :hint="item.hinweis"
    :persistent-hint="true"
    :placeholder="item.eingabehilfe"
    :suffix="item.einheit"
    :rows="getTextareaRows(item)"

  ></v-textarea>
  <div  v-else-if="item.attributart === 10">

  <v-textarea
    :label="item.bezeichnung"
    :value="item.text"
    :filled="item.update"
    v-model="item.value"
    :auto-grow="true"
    :rules="rules()"
    :hint="item.hinweis"
    :persistent-hint="true"
    :placeholder="item.eingabehilfe"
    :suffix="item.einheit"
    :rows="getTextareaRows(item)"
  ></v-textarea>

  <div class="ci-svg-elment" v-if="elementSVGWidth > 0 && elementSVGHeight > 0">
    
    <div style="padding: 6px; background: #f2f2f2;"  :style="'width: ' +  ((elementSVGWidth * 20) + 12)  + 'px; height: ' +  ((elementSVGHeight   * 20) + 12) + 'px;'">
    <div style="background: #f2f2f2;"  :style="'width: ' +  (elementSVGWidth* 20)  + 'px; height: ' +  (elementSVGHeight  * 20)  + 'px;'">
      <svg fill="#ffffff" :style="'width: ' +  (elementSVGWidth* 20) + 'px; height: ' +  (elementSVGHeight  * 20) + 'px;'" v-html="getElementSVGStyle() + item.value">

      </svg>
    </div>
    </div>
    
  </div>

  </div>
  <v-select
    v-else-if="item.attributart === 91"
    :label="item.bezeichnung"
    :items="selectItems()"
    item-text="bezeichnung"
    item-value="id"
    v-model="item.value"
    :filled="item.update"
    :rules="rules()"
    :hint="item.hinweis"
    :persistent-hint="true"
    :placeholder="item.eingabehilfe"
    :suffix="item.einheit"

  ></v-select>

  <v-radio-group
    v-else-if="item.attributart === 9"
    v-model="item.value"
    :readonly="!item.update"
    :label="item.bezeichnung"
    class="ci-select-items"
    :hint="item.hinweis"
    :persistent-hint="true"
    :suffix="item.einheit"
    :placeholder="item.eingabehilfe"
    @change="onItemChange()"
  >
    <v-radio v-for="wl in selectItems()" :key="item.id + '-wl-' + wl.id" :label="wl.bezeichnung" :value="wl.id"></v-radio>
  </v-radio-group>


  <v-input
    v-else-if="item.attributart == 11"
    @click="downloadFile()"
    :append-icon="(item.loesch_link)? 'delete': ''"
    @click:append="deleteFile()"
    prepend-icon="pdf"
    :hint="item.hinweis"
    :persistent-hint="true"
    :placeholder="item.eingabehilfe"
  >{{item.original_name}}</v-input>

  <v-card  v-else-if="item.attributart == 12">
      <v-img :src="dataSrc"  contain height="200"  :max-width="item.weite" ></v-img>
      <v-card-actions>
        <span class="body-2 font-weight-light">{{ item.original_name }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="deleteFile()"><v-icon>delete</v-icon></v-btn>
      </v-card-actions>
  </v-card>

  <span v-else-if="item.attributart == 20">
    <div  class="ci-readonly-feld">
      <div class="ci-bezeichnung  primary--text"> {{item.bezeichnung }}</div>
    </div> 
  </span>
  
  <span v-else-if="item.attributart == 21">
    <div  class="ci-readonly-feld">
      <div class="ci-bezeichnung  primary--text"> {{item.bezeichnung }}</div>
    </div> 
  </span>

  <v-file-input
    v-else-if="(item.attributart == 22 || item.attributart == 23)"
    :label="item.bezeichnung"
    :placeholder="(item.max)? item.buttontext  + ' (' + item.max + ')' : item.buttontext"
    v-model="files"
    :loading="uploading"
    :disabled="uploading"
    :multiple="(item.max && item.max == 1)? false: true"
    small-chips
    :max="item.max"
    :error-messages="fileErrors"
    :error-count="10"
    show-size
    :accept="(item.attributart == 22)? 'image/png, image/jpeg, image/bmp':''"
    @change="upload"
    :hint="item.hinweis"
    :suffix="item.einheit"
    :persistent-hint="true"
  ></v-file-input>

  <v-text-field
    v-else
    :prepend-icon="prependIcon()"
    :label="item.bezeichnung"
    :value="wert()"
    :type="type()"
    :readonly="!item.update"
    v-model="item.value"
    :rules="rules()"
    :step="step()"
    :filled="item.update"
    :hint="item.hinweis"
    :persistent-hint="true"
    :suffix="item.einheit"
    :placeholder="(!item.eingabehilfe && item.einheit)?' ':item.eingabehilfe"
    @click:prepend="changePrependIcon"
    @change="inputOnChange"
  ></v-text-field>
  </span>
  </span>
</template>
<script>

import "prismjs";
import "prismjs/themes/prism.css";

import { mapGetters } from "vuex";
import { PageService } from "@/common/api.service";
import {
  FETCH_START,
  FETCH_END,
  SET_CONFIRM,
  SET_ALERT,
  SET_ALERT_RESPONSE,
  SET_SELEMENT_SVG_WIDTH,
  SET_SELEMENT_SVG_HEIGHT,
  RELAOD_ATTRIBUTE
} from "../store/mutations.type";
import { FETCH_PAGE, NAVI_PAGE } from "../store/actions.type";

export default {
  name: "QAttribut",
  props: ["item"],
  computed: {
    ...mapGetters(["daten", "attribute", "elementSVGWidth","elementSVGHeight"]), 

  },

  data: () => ({
    uploading: false,
    files: [],
    fileErrors: [],
    dataSrc: "",
    prependIdx: -1,
    prependList: []
  }),
  mounted() {
    
  if (this.$props.item.prefix){   
    let idx = 0;
      this.$props.item.prefix.forEach(item => {
        this.prependList.push(item.icon)
        if (item.checked) {
          this.prependIdx = idx;
        }
        ++idx

      });

    }

    if (this.$props.item.attributart == 12) {
      PageService.file(this.$props.item.navi, "application/pdf")
        .then(({ data }) => {
          this.dataSrc = window.URL.createObjectURL(new Blob([data]));
        })
        .catch(() => {
           this.dataSrc = require('../assets/no-image.png');
        });
      }
      this.fillSVGDimension()
  },
  methods: {
    onItemChange(){
        if (this.$props.item.reloadForm) {
           this.$store.commit(RELAOD_ATTRIBUTE);
        }
    },
    fillSVGDimension(){
      let value;
      if (this.$props.item.id == 7049112){
        value = 0;
        
        if (this.$props.item.value) {
           value = this.$props.item.value 
        }
        this.$store.commit(SET_SELEMENT_SVG_WIDTH, value);
      }
      if (this.$props.item.id == 7049113){
        value = 0;
        
        if (this.$props.item.value) {
           value = this.$props.item.value 
        }
        this.$store.commit(SET_SELEMENT_SVG_HEIGHT, value);
      }
    },
    prependIcon(){
        if ( this.$props.item.prefix){
          return this.prependList[this.prependIdx]
        }
      return ""
    },
    wert(){
      if (this.$props.item.text !== undefined) {
        return  this.$props.item.text
      }
      if (this.$props.item.datum !== undefined) {
        return  this.$props.item.datum
      }
      if (this.$props.item.zahl !== undefined) {
        return  this.$props.item.zahl
      }     
          

    },
    deleteFile() {

      if (this.$props.item.loesch_link) {
        let pageParam = this.copyObject(this.$router.currentRoute.params);

        
       

        if (pageParam.pfkt && pageParam.pfkt != 2) {
          delete pageParam.pfkt;
        }

        this.$store.commit(SET_CONFIRM, {
          message: "Wirklich löschen?",
          link: this.$props.item.loesch_link,
          pageParam: pageParam
        });
      }
    
    },
    rotateImage() {
      /*
      let pageParam = this.copyObject(this.$props.item.navi);
      pageParam.pfkt = 7

      this.$store.dispatch(POST_ATTRIBUT, {
        navi: pageParam
      }).then((daten) => {
        console.log("test", daten)
      });
      */

      PageService.rotate(this.$props.item.navi)
        .then(() => {
          this.$store.commit(FETCH_END);
          //console.log("test", data)
        })
        .catch(({ response }) => {
          this.$store.commit(FETCH_END);
          this.$store.commit(SET_ALERT_RESPONSE, response);
        });
        
        
    },    
    afterUpload(index, anz) {
      if (index === anz) {
        this.uploading = false;
        this.files = [];
        let pageParam = this.copyObject(this.$router.currentRoute.params);

        if (pageParam.pfkt && pageParam.pfkt != 2) {
          delete pageParam.pfkt;
        }    

        this.$store.dispatch(FETCH_PAGE, pageParam);
        if (this.fileErrors.length > 0) {
          this.$store.commit(SET_ALERT, "Fehler beim Hochladen!");
        }
      }
    },
    nav(){
      console.log(this.item)
      this.$store.dispatch(NAVI_PAGE, this.item)
    },upload() {

      if (this.files.length > 0) {        
        this.fileErrors = [];
        let anz = this.files.length;

        if (this.$props.item.max && (anz > this.$props.item.max)) {
          this.fileErrors.push(
              "Es können maximal  " + this.$props.item.max + " Dateien ausgewählt werden!"
          );
          return;
        } 

        this.uploading = true;
        let cnt = 0;        
        this.files.forEach(file => {
          cnt = cnt + 1;
          if (file.size > 1024 * 1024 * process.env.MAX_FILE_SIZE) {
            this.fileErrors.push(
              "Datei größer als " + process.env.MAX_FILE_SIZE + " MB! (" + file.name + ")"
            );

            this.afterUpload(cnt, anz);
          } else {
            let formData = new FormData();

            Object.keys(this.$props.item.navi).forEach(key => {
              formData.append(key, this.$props.item.navi[key].toString());
            });

            formData.append("file", file);

            PageService.upload(this.$props.item.navi, formData)
              .then(() => {
                this.afterUpload(cnt, anz);
              })
              .catch(({ response }) => {
                let errorMessage;
                if (response) {
                  if (
                    response.data &&
                    response.data.error &&
                    response.data.error.message
                  ) {
                    errorMessage = response.data.error.message;
                  } else if (response.statusText) {
                    errorMessage = response.statusText;
                  } else {
                    errorMessage = "Unbekannter Fehler";
                  }
                }

                if (errorMessage) {
                  this.fileErrors.push(errorMessage + " (" + file.name + ")");
                }

                this.afterUpload(cnt, anz);
              });
          }
        });
      }
    },
    downloadFile() {      
      this.$store.commit(FETCH_START);
      PageService.file(this.$props.item.navi)
        .then(({ data }) => {
          this.$store.commit(FETCH_END);
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.$props.item.original_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch(({ response }) => {
          this.$store.commit(FETCH_END);
          this.$store.commit(SET_ALERT_RESPONSE, response);
        });
        
    },
    /*
    blob() {
      PageService.file(this.$props.item.navi, "application/pdf")
        .then(({ data }) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          return url;
        })
        .catch(({ response }) => {
          this.$store.commit(SET_ALERT_RESPONSE, response);
        });
    },*/
    selectItems() {
      let items = [];
      this.$store.getters.attribute.forEach(attribut => {
        if (attribut.oben === this.$props.item.id) {
          items.push(attribut);
        }
      });

      return items;
    },

    changePrependIcon(){

      if (this.prependIdx > -1) {
          this.$props.item.prefix[this.prependIdx].checked = false;          
          this.prependIdx === this.prependList.length - 1 ? this.prependIdx = 0 : this.prependIdx++;          
          this.$props.item.prefix[this.prependIdx].checked = true;
      }
     
    },
    step() {
      let step = 'any';
      if (this.$props.item.attributart === 8 && this.$props.item.nachkommastellen && this.$props.item.nachkommastellen > 0) {
          step = '0.';
          for (let i = 1; i <= this.$props.item.nachkommastellen; i++) {
            if (i ===  this.$props.item.nachkommastellen) {
              step = step + '1';
            } else { 
                step = step + '0';
            }
          }
      }
      return step
    },
    rules() {
      let rules = [];

      if (this.$props.item.mussfeld) {
        if (this.$props.item.attributart === 8) {
          rules.push(!!this.$props.item.value || "Bitte eine Zahl eingeben!");
        } else {
          rules.push(!!this.$props.item.value || "Feld muss gefüllt sein!");
        }
      }

      if (this.$props.item.attributart === 16) {
        const mailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        rules.push(
          !this.$props.item.value ||
            mailPattern.test(this.$props.item.value) ||
            "Bitte eine E-Mail-Adresse eingeben (test@test.de)!"
        );
      }

      if (this.$props.item.min && (this.$props.item.value || this.$props.item.value === 0) ) {
        if (this.$props.item.attributart === 8) {
          rules.push(
            this.$props.item.value >= this.$props.item.min ||
              "Minimum: " + this.$props.item.min + "!"
          );
        } else {
          rules.push(
            this.$props.item.value.length >= this.$props.item.min ||
              "Mindestens: " + this.$props.item.min + " Zeichen!"
          );
        }
      }

      if (this.$props.item.max && (this.$props.item.value || this.$props.item.value === 0)) {
        if (this.$props.item.attributart === 8) {
          rules.push(
            this.$props.item.value <= this.$props.item.max ||
              "Maximum: " + this.$props.item.max + "!"
          );
        } else {
          rules.push(
            this.$props.item.value.length <= this.$props.item.max ||
              "Maximal: " + this.$props.item.max + " Zeichen!"
          );
        }
      }

      return rules;
    },
    type() {
      if (this.$props.item.attributart === 7) {
        return "date";
      }
      if (this.$props.item.attributart === 8) {
        return "number";
      }
      return "text";
    },
    printElementSVG(){
      let width, height;
      this.$store.getters.attribute.forEach(attribut => {
        if (attribut.id === 7049112) {
          width = attribut.text;
        }else if (attribut.id === 7049113) {
          height = attribut.text;
        }
      });

      return '<svg fill="#ffffff" style="width: ' + (width * 20) + 'px; height: ' + (height * 20) + 'px;">' + this.getElementSVGStyle() + this.$props.item.text + '</svg>'
    },
    inputOnChange(){
      this.fillSVGDimension()
    },
    getElementSVGStyle(){
      let style = `
        text {
          font-size: 11pt;
          font-family:'Helvetica'; 
          font-weight: normal;
          fill: #000000;
          letter-spacing: 1px;
        }
        `;
      return "<style>" + style + "</style>";
    },
    getTextareaRows(){
      if (this.daten.einzelsatz.dynattr) {
        return 1
      }


      return 5
    }
  }
};
</script>
