<template>

    <v-row>
      <template v-for="(item, index) in attribute" :index="index">
          <template v-if="parent.id && item.oben == parent.id && (item.attributart !== 18)">
            <!-- Umbruch bei Upload -->
            <v-col
                v-bind:key="'1-' + parent.id + '-' + item.id"
                v-if="item.attributart == 22 || item.attributart == 23"
                cols="12"
                :sm="12"
                :lg="12"          
              >
            </v-col>
            <!-- Ueberschrift bei 2 und 4 -->
              <v-col
                v-bind:key="'2-' + parent.id + '-' + item.id"
                v-if="(parent.attributart === 11 || parent.attributart === 12) && item.bezeichnung"
                cols="12"
              >
                <div>{{ item.bezeichnung }} </div>
              </v-col>
              <!-- Umbruch bei Dateien -->
            <v-col
                v-bind:key="'3-' + parent.id + '-' + item.id"
                v-if="(item.attributart == 11 || item.attributart == 12) &&  item.bezeichnung"
                cols="12"
                :sm="12"
                :lg="12"          
              >
              <div class="ci-readonly-feld">
                  <div class="ci-bezeichnung"> {{item.bezeichnung }}</div>
              </div> 
            </v-col>
              <v-col
                v-bind:key="'4-' + parent.id + '-' + item.id"           
                cols="12"
                :sm="colsSM(item)"
                :lg="colsLG(item)"
                :class="'ci-attributart ci-attributart-' + item.attributart"
              >
     
              <Attribut :item="item"></Attribut>
                  <QAttribute  :parent="item"></QAttribute>
              </v-col>
            </template>
      </template>
    </v-row>

</template>
<script>
import { mapGetters } from "vuex";
import Attribut from "@/components/Attribut.vue";

export default {
  name: "QAttribute",
  props: ["parent"],
  computed: {
    ...mapGetters(["daten","attribute"])
  },
  components: {
    Attribut,
  },
  methods: {

      colsSM(item){

          if (item.attributart === 12) {
              return 6
          }
          return 12
      },

      colsLG(item){
          if (item.attributart === 25) {
              return 6
          }     
          if (item.attributart === 12) {
              return 4
          }
          if (item.attributart == 22 || item.attributart == 23) {
              return 12 //3
          }          
          return 12
      }
  }
};
</script>
