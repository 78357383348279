<template v-if="daten && daten.liste">
  <div v-if="daten.liste.darstellung">
    <div v-if="daten.liste.darstellung === 'kacheln'">
      <!-- <v-container class="grey lighten-5"> -->
        <v-container class="ci-content">
        <v-row>
          <v-col
            :id="'ci-list-item-' + item.id"
            v-for="(item, index) in daten.liste.saetze"
            :item="item"
            :key="item.titel + index"
            cols="12"
            :sm="6"
            :lg="4" 
           
          >
            <v-card class="pa-1" elevation="1" outlined   :color="farbcode(item)"  @click="navi(item)" height="200" >
              <div class="ci-home-card-icons">
                <v-icon>{{ item.icon }}</v-icon>
              </div>
          
              <v-card-actions style="min-height: 48px">
                <span>{{ item.titel }}</span><v-spacer v-if="item.anzahloffen !== undefined "></v-spacer> <v-chip v-if="item.anzahloffen">{{ item.anzahloffen }}</v-chip>  
              </v-card-actions>

   
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-if="daten.liste.darstellung === 'liste'">
      <!--<v-container class="grey lighten-5">-->
      <v-container  class="ci-content">

        <v-list-item-group :value="selectedSeite" >
          <template v-for="(item, index) in daten.liste.saetze">
            <v-list-item :class="(item.stufe)? 'ci-list-item-stufe-' + item.stufe : ''"  :id="'ci-list-item-' + item.id" :value="item.id" :key="item.id"  @click="navi(item)" :inactive="!item.navi">
              <template v-slot:default="{}">
                <v-list-item-icon v-if="item.icon">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.titel" ></v-list-item-title>
                  <v-list-item-subtitle >{{ (item.untertitel)?  item.untertitel : '&nbsp;' }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action v-if="item.datum || item.anzahloffen !== undefined || item.farbcode !== undefined || item.aktionsmenue !== undefined"> 
                  <v-list-item-action-text>{{ (item.datum)? new Date(item.datum).toLocaleDateString('de-DE',dateOption) : '&nbsp;' }}</v-list-item-action-text>
                   <div v-if="item.anzahloffen !== undefined || item.farbcode !== undefined || item.aktionsmenue">
                     <span v-if="item.aktionsmenue !== undefined">
                      <v-btn  v-for="(item, index) in item.aktionsmenue"  :key="item.text + index" icon @click="navi(item)"><v-icon>{{ item.icon }}</v-icon></v-btn>
                     </span>
                      <v-chip v-if="item.farbcode !== undefined" label :color="farbcode(item)" :text-color="textfarbcode(item)">{{ (item.bewertungstext !== undefined)? item.bewertungstext : '&nbsp;' }}</v-chip>
                      {{  (item.anzahloffen !== undefined && item.farbcode !== undefined)? '&nbsp;': '' }}
                      <v-chip v-if="item.anzahloffen">{{ item.anzahloffen }}</v-chip>  
              
                    </div>
                </v-list-item-action>
              </template>
            </v-list-item>

            <v-divider v-if="index + 1 <  daten.liste.saetze.length" :key="'spacer-' + index"></v-divider>
          </template>
        </v-list-item-group>
        <div v-if="daten && daten.liste.saetze && daten.liste.saetze.length" class="ci-treffer">Anzahl: {{ daten.liste.saetze.length }}</div>
      </v-container>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { NAVI_PAGE} from "../store/actions.type";

export default {
  name: "QList",
  data: () => ({
   dateOption: { year: 'numeric', month: '2-digit', day: '2-digit' }
  }),
  mounted() {
      this.$nextTick(function () {
        let options = {
          duration: 0
        }
        
        if (this.$store.getters.selectedSeite) {
          this.$vuetify.goTo('#ci-list-item-' + this.$store.getters.selectedSeite, options);         
        }else {
        
          this.$vuetify.goTo(0, options);
        }
      })
  },
  methods: {
    navi(item){
      this.$store.dispatch(NAVI_PAGE, item)
    },
    textfarbcode(item) {
      if (item.farbcode == 1) {
        return 'rgb(255, 255, 255)'
      }
    },
    farbcode(item) {
      if (item.farbcode == 1) {
        return 'rgb(25, 121, 107)'
      }
      if (item.farbcode == 2) {
        return 'rgb(255, 236, 139)'
      }
      if (item.farbcode == 3) {
        return 'rgb(230, 148, 54)'
      }
      if (item.farbcode == 4) {
        return 'rgb(238, 162, 173)'
      }
      if (item.farbcode == 5) {
        return '#708090'
      }
      return;
    },
    levelPadding(item){
      
        if (item.stufe && item.stufe > 1){
     
          if (item.stufe > 4) {
            return (45 * 4)  + "px";
          }

          return (45 * item.stufe)  + "px";

        }

        return (24) + "px";
    }
  },
  computed: {
    ...mapGetters(["selectedSeite", "daten"]),
  }
};
</script>
