
<template >
    <div>
        <div class="text-left" style="padding: 20px; padding-bottom: 0">
            <span>
                <v-btn @click="showGrid = !showGrid"><v-icon v-if="!showGrid">grid_on</v-icon><v-icon v-if="showGrid">grid_off</v-icon></v-btn>              
                <v-btn class="ml-1" @click="zoomIn()" :disabled="scale >= 2.8"><v-icon>zoom_in</v-icon></v-btn>                
                <v-btn class="ml-1" @click="zoomOut()" :disabled="scale <= 0.6"><v-icon>zoom_out</v-icon></v-btn>
            </span>

          <!--
          <v-btn @click="setMultiSelection()" :color="(multiSelection)?'error':''">Mehrfachauswahl</v-btn>
          <v-btn @click="moveAll(4)"><v-icon>mdi-arrow-expand-left</v-icon></v-btn>  
          <v-btn @click="moveAll(2)"><v-icon>mdi-arrow-expand-right</v-icon></v-btn>
          <v-btn @click="moveAll(1)"><v-icon>mdi-arrow-expand-up</v-icon></v-btn>
          <v-btn @click="moveAll(3)"><v-icon>mdi-arrow-expand-down</v-icon></v-btn>
          -->
          <span v-if="!multiSelection">
              <span v-if="showElementDetails && selecedElement && selecedElement.attr && selecedElement.attr('data-allow-rotate')">
                  <v-btn class="ml-3" @click="rotateElement()" ><v-icon>rotate_right</v-icon> Drehen</v-btn>
              </span>
              <!--
              <span v-if="showElementDetails && selecedElement && selecedElement.attr && selecedElement.attr('data-allow-mirror')">
                  <v-btn  ><v-icon>flip</v-icon> Spiegeln</v-btn>
              </span>
              -->

          </span>    
          <div   style="min-height: 28px; padding-top: 4px">
             <span  v-html="showElementDesc()" />
          </div>                      
        </div>

        <div style="padding: 20px; padding-top: 10px;">
            <div id="svg-frame">
                <div   id="svg-wrapper"  v-bind:class="{ 'no-scroll': disableScrolling }">
                    <svg  id="svg" fill="#FFFFFF" height="1500" width="3000">
                        <g id="svgout" :transform="transformScale()" cidb-scale="1.0">
                          <defs>
                              <pattern id="gridpane" width="20" height="20" patternUnits="userSpaceOnUse">
                              <path d="M 20 0 L 0 0 0 20" fill="none" stroke="gray" stroke-width="0.5"/>
                              </pattern>
                          </defs>
                          <rect width="10000" height="10000" fill="none"  />
                          <rect width="3000" height="1500" fill="none"  stroke="gray" stroke-width="1.5"/>
                          <rect width="3000" height="1500" :fill="(showGrid)?'url(#gridpane)': 'none'" id="grid" />

                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

   

    .not-allowed {
        fill: red;
    }

    g.selected {
        fill: blue;
    }

    #svg-wrapper {
        flex: 1;
        overflow: scroll;
        width: 100%;
        /*height: calc(100vh - 142px);*/
        height: calc(100vh - 280px);
        background: #f2f2f2;
    }

    #svg-wrapper.no-scroll {
        overflow: hidden;
    }

    .sorting.hide{
        display: none;
    }
</style>   

<script>
import { POST_SCHEMA_ELEMENTE} from "../store/actions.type";

import "snapsvg-cjs";
import { mapGetters } from "vuex";
//import { POST_SEARCH } from "@/store/actions.type";
//import { RESET_SEARCH } from "@/store/mutations.type";


let g_maxx = 3000;  //3000
let g_maxy = 1500; // 1500
let gridSize = 20;
let Snap = window.Snap;



export default {
  computed: {
    ...mapGetters(["isLoading", "isRequesting", "daten"])
  },
  watch: {
    daten () {
      console.log("watch!!!", this.daten.schema )

      if (this.daten && this.daten.schema && this.daten.schema.elemente){
        this.daten.schema.elemente.forEach( element => {
            this.addElement(element)
        });
      }
    }
  },
  data() {
    return { 
      scale: 1.0,
      currentScale: 1,
      selecedElement: undefined,
      multiSelection: false,
      disableScrolling: false,
      showElementDetails: false,
      showGrid: true,
      elementDetails: "",
      s: undefined 
    };
  },
  mounted() { 

	window.addEventListener("keyup", e => {
      let direction;
      if (e.key == "Escape") {
        this.unselect()
        return;
      }
      if (e.key == "w" || e.key == 'ArrowUp') {
        direction = 1;
      }else if (e.key == "d"  || e.key == 'ArrowRight'){
        direction = 2;
      }else if (e.key == "s"  || e.key == 'ArrowDown'){
        direction = 3;
      }else if (e.key == "a"  || e.key == 'ArrowLeft'){
        direction = 4;
      }
      if (direction) {
         this.moveAll(direction)
         return;
      }
  });

/*
    window.addEventListener("keypress", e => {
      let direction;
      console.log(e.Key)
      if (e.key == "w" || e.Key == 'ArrowUp') {
        direction = 1;
      }else if (e.key == "d"){
        direction = 2;
      }else if (e.key == "s"){
        direction = 3;
      }else if (e.key == "a"){
        direction = 4;
      }
      if (direction) {
         this.moveAll(direction)
      }
    });
*/
    this.s = window.Snap("#svgout");
    let content = this;
    this.s.click(function(e){ 
      if (!content.multiSelection && e && e.target && (e.target.id == 'grid' || e.target.id == 'grid' )) {
        Snap.selectAll(".selected").forEach(function(el) {       
          el.removeClass("selected");
          content.showElementDetails = false
          content.selecedElement = undefined
        });
      }
    });

    setTimeout(() => {
      if (this.daten && this.daten.schema && this.daten.schema.elemente){
          this.daten.schema.elemente.forEach( element => {
              this.addElement(element)
          });
        }
    })
  },  
  name: "SchemaEditor",
  components: {
    //Footer
  },  
  methods: {
    navigate() {
      /*console.log(event.keyCode)*/
    },
    saveSchema(){
      Snap.selectAll(".objekt").forEach(function() {

        });
    },
    getElementsAttribute(ele){
      let sortElement = ele.select(".sorting")
      let data = ele.node.dataset;
      let matrix = ele.matrix;
      let sortMatrix = sortElement.transform().localMatrix;

      let attribute = {}
      attribute["id_" + data.id] = data.id
      attribute["matrix_" + data.id] = "matrix("+matrix.a+","+matrix.b+","+matrix.c+","+matrix.d+","+matrix.e+","+matrix.f+")"
      attribute["lage_" + data.id] = data.rotate
      attribute["sort_matrix_" + data.id] = "matrix("+sortMatrix.a+","+sortMatrix.b+","+sortMatrix.c+","+sortMatrix.d+","+sortMatrix.e+","+sortMatrix.f+")"
      
      //attribute["matrix_" + data.id] = ""
      
      // attribute["sort_matrix_" + data.id] = ""
      // attribute["lage_" + data.id] =  ""
      return attribute;
    },
    saveElement(ele){      
      let navi = {
        pfkt: 11001,
        p_id:  this.daten.kontext.id,
      }

      this.$store.dispatch(POST_SCHEMA_ELEMENTE, {navi, attribute: this.getElementsAttribute(ele)});    
    },
    saveElemente(elemente){
      
      let navi = {
        pfkt: 11001,
        p_id:  this.daten.kontext.id,
      }

      let attribute = {}
      elemente.forEach(ele => {
        Object.assign(attribute, this.getElementsAttribute(ele));
      })
  
      this.$store.dispatch(POST_SCHEMA_ELEMENTE, {navi, attribute});    
  
    },    
    zoomIn(){
       if (this.scale >= 2.8) {
         return;
       }
       this.scale = this.scale + 0.2;
    },
    zoomOut(){
      if (this.scale <= 0.6) {
         return;
      }
      this.scale = this.scale - 0.2;
    },
    transformScale(){
      let scale = this.scale + ',' + this.scale
      //console.log(scale)
      return "scale(" + scale + ")"
    },
    toogleSort(){
        Snap.selectAll(".sorting").forEach(function(el) {
          el.toggleClass("hide")
        });
    },
    unselect(){
      Snap.selectAll(".selected").forEach(function(el) {       
            el.removeClass("selected");          
      });

      this.showElementDetails = false
      this.selecedElement = undefined            
      this.multiSelection = false;


    },
    setMultiSelection(){
      if (this.multiSelection){
         Snap.selectAll(".selected").forEach(function(el) {       
            el.removeClass("selected");          
        });

      }
      this.showElementDetails = false
      this.selecedElement = undefined            
      this.multiSelection = !this.multiSelection
    },
    checkAllowed(){        
        let error = false;
        Snap.selectAll(".objekt").forEach(function(element) {
          error = false;
          Snap.selectAll(".objekt").forEach(function(el) {
        
            if (element.id != el.id && Snap.path.isBBoxIntersect(element.getBBox(), el.getBBox())){
       
                element.addClass("not-allowed");
                error = true;
                return;
            }        
          });

          if (error == false){
            element.removeClass("not-allowed");
          }
        });
    },
    showElementDesc() {
      if (this.selecedElement && this.selecedElement.attr("data-desc")){
        return this.selecedElement.attr("data-desc")
      } 
    },
    rotateElement(){
        let bbox = this.selecedElement.getBBox();
        let noBottomText  = this.selecedElement.select(".no-bottom-text")

        let rotation =  Number(this.selecedElement.attr("data-rotate"))
        let alignment;
        if (rotation == 2 || rotation == 3) {
          if (noBottomText){                  
             if (rotation == 2) {
                alignment = noBottomText.transform().localMatrix.f;
             }else if (rotation == 3) {
                alignment = noBottomText.transform().localMatrix.f;
              }                          
          }  
        }      

        this.selecedElement.attr({
              transform: this.selecedElement.transform().local + (this.selecedElement.transform().local ? "R" : "r") + [90, bbox.cx,bbox.cy]          
        });

        if (rotation == 4) {
          this.selecedElement.attr("data-rotate", 1);
        }else {
          this.selecedElement.attr("data-rotate", rotation + 1);
        }
        let sort = this.selecedElement.select(".sorting");

        sort.attr({
              transform: sort.transform().local + (sort.transform().local ? "R" : "r") + [-90]          
        });

        if (rotation == 1) {
          sort.attr({
            transform: sort.transform().local + (sort.transform().local ? "T" : "t") + [bbox.width - 18,0]          
          });
        }else if (rotation == 2) {
          sort.attr({
            transform: sort.transform().local + (sort.transform().local ? "T" : "t") + [0,-1 * (bbox.width - 18)]          
          });                 
          if (noBottomText){     
            noBottomText.attr({
              transform: noBottomText.transform().local + (noBottomText.transform().local ? "R" : "r") + [-180]          
            });
            
            noBottomText.attr({
              transform: noBottomText.transform().local + (noBottomText.transform().local ? "T" : "t") + [0,(bbox.width - alignment - noBottomText.transform().localMatrix.f)]          
            });
          }
        } else if (rotation == 3) {       
          sort.attr({
              transform: sort.transform().local + (sort.transform().local ? "T" : "t") + [-1 * (bbox.width - 18),0]          
          });
       if (noBottomText){     
            noBottomText.attr({
              transform: noBottomText.transform().local + (noBottomText.transform().local ? "R" : "r") + [-180]          
            });
            noBottomText.attr({
              transform: noBottomText.transform().local + (noBottomText.transform().local ? "T" : "t") + [0,(bbox.width - alignment - noBottomText.transform().localMatrix.f)]          
            });
          }
        }else {          
          sort.attr({
              transform: sort.transform().local + (sort.transform().local ? "T" : "t") + [0,(bbox.width - 18)]          
          });            
        }

        let text = this.selecedElement.select(".fixed-text");
        if (text) {
          text.attr({
            transform: text.transform().local + (text.transform().local ? "R" : "r") + [-90]          
          });
        }
        this.checkAllowed();

        this.selecedElement.appendTo(this.s)
        this.selecedElement.data('origTransform',  this.selecedElement.transform().local);      
        this.selecedElement.data('ibb',  this.selecedElement.getBBox() );   
        this.selecedElement.data('rotation',  Number( this.selecedElement.attr("data-rotate")))
    

        this.saveElement(this.selecedElement)

      
    },
    moveAll(direction){
      let l_move_allowed = true
   //   let selecedClass = '.objekt';
      let content = this; 

/*
      if (content.selecedElement || content.multiSelection) {
        selecedClass = selecedClass + '.selected';
      }
      */

      Snap.selectAll('.objekt.selected').forEach(function(obj) {
          let element = obj
          let width = Number(obj.attr("data-width"))
          let height = Number(obj.attr("data-height"))

          /*
          console.log("move")
          console.log("rotation: " + element.data("rotation"))
          console.log("a"+element.matrix.a)
          console.log("b"+element.matrix.b)
          console.log("c"+element.matrix.c)
          console.log("d"+element.matrix.d)
          console.log("e" +element.matrix.e)
          console.log("f" +element.matrix.f)

          var obj0PointX = ( element.data('ibb').x - 1);
          var obj0PointY = ( element.data('ibb').y - 1);    
          console.log("obj0PointX:" + obj0PointX)
          console.log("obj0PointY:" + obj0PointY)
          console.log("direction:" + direction)
*/

          var size = 0;
          if (element.data("rotation") == 1) {
            if (direction == 2) {
                size = element.matrix.e + width;
            }else if  (direction == 3) {
                size = element.matrix.f + height;
            }else if  (direction == 4) {
                size = element.matrix.e;
            }else {
              size = element.matrix.f
            }
          } else if (element.data("rotation") == 2) {
            if (direction == 2) {
                size = element.matrix.e + width;
            }else if  (direction == 3) {
                size = element.matrix.f + height;
            }else if  (direction == 4) {
                size = element.matrix.e - height;
            }else {
              size = element.matrix.f
            }
          } else if (element.data("rotation") == 3) {
            if (direction == 2) {
                size = element.matrix.e;
            }else if  (direction == 3) {
                size = element.matrix.f;
            }else if  (direction == 4) {
                size = element.matrix.e - width;
            }else {
              size = element.matrix.f - height
            }                
          } else if (element.data("rotation") == 4) {
            if (direction == 2) {
                size = element.matrix.e + height;
            }else if  (direction == 3) {
                size = element.matrix.f;
            }else if  (direction == 4) {
                size = element.matrix.e;
            }else {
              size = element.matrix.f - width
            }                
          }          

          if ((direction == 1 && size <= 0) ||
              (direction == 2 && size >= g_maxx) ||
              (direction == 3 && size >= g_maxy) ||
              (direction == 4 && size <= 0)
              ){
                l_move_allowed = false
                return;
            }
      });

      if (l_move_allowed === true) {
        let objList = []
        Snap.selectAll('.objekt.selected').forEach(function(obj) {
            var element =obj
            var matrix = element.matrix;
            if (direction == 1){
                matrix.f = matrix.f - gridSize;
            }else if (direction == 2){
                matrix.e = matrix.e + gridSize;
            }else if (direction == 3){
                matrix.f = matrix.f + gridSize;
            }else if (direction == 4){
                matrix.e = matrix.e - gridSize;
            }

            element.attr({
                transform: matrix
            });

  
            objList.push(obj)
            //content.saveElement(obj)



             if (content.selecedElement || content.multiSelection){
               content.checkAllowed()
             }

        });
        if (objList.length > 0) {
          content.saveElemente(objList)
        }


      }
    },
    addDrag(pElement, content){
      let start = function(x) { // function(x, y, ev)
        content.disableScrolling = true;

        let touch = false
        if( (typeof x == 'object') && ( x.type == 'touchstart') ) {
          x.preventDefault();
          touch = true
        }

        if (content.multiSelection) {
          this.toggleClass("selected");            
          return;
        }

        this.data('origTransform', this.transform().local);      
        this.data('ibb', this.getBBox() );   
        this.data('rotation',  Number(this.attr("data-rotate")))
        
        if(touch) {
            this.data('ox', x.changedTouches[0].clientX);
            this.data('oy', x.changedTouches[0].clientY);  
        }else {            
            this.data('ox', this.transform().localMatrix.e);
            this.data('oy', this.transform().localMatrix.f);  
        }

        this.appendTo(this.s);
                  
        Snap.selectAll(".selected").forEach(function(el) {       
            el.removeClass("selected");                  
        });
        
        content.showElementDetails = true;
        content.selecedElement = this;
        this.addClass("selected");
      //this.parent().appendTo(this.s);
      }

      let move = function(dx,dy) { //function(dx,dy, x, y, e

        content.disableScrolling = false
        if (content.multiSelection) {
          return;
        }
        let touch = false;
        let objWidth = this.data('ibb').width;
        let objHeight =  this.data('ibb').height;

        let obj0PointX = this.data('ox');
        let obj0PointY = this.data('oy');


        if( (typeof dx == 'object') && ( dx.type == 'touchmove') ) {
          touch = true;
          let clientX = dx.changedTouches[0].clientX;
          let clientY = dx.changedTouches[0].clientY;
          dx = clientX - obj0PointX;
          dy = clientY - obj0PointY;
          obj0PointX = ( this.data('ibb').x - 1);
          obj0PointY = ( this.data('ibb').y - 1);    
        } 

        let snapInvMatrix = this.transform().diffMatrix.invert();
        snapInvMatrix.e = snapInvMatrix.f = 0;

        let tdx = snapInvMatrix.x( dx,dy );
        let tdy = snapInvMatrix.y( dx,dy );
        let xSnap = Snap.snapTo(gridSize, tdx, 100000000);
        let ySnap = Snap.snapTo(gridSize, tdy, 100000000);

        if (touch){
          if ( obj0PointX + xSnap >= 0 && 
              obj0PointX + xSnap + objWidth <= g_maxx  && 
              obj0PointY + ySnap >= 0 && 
              obj0PointY + ySnap + objHeight <= g_maxy) {
                this.attr({
                transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [xSnap, ySnap]          
                });
            }  
        } else {
          if (this.data("rotation") == 1) {
            if ( obj0PointX + xSnap >= 0 && 
                  obj0PointX + xSnap + objWidth <= g_maxx  && 
                  obj0PointY + ySnap >= 0 && 
                  obj0PointY + ySnap + objHeight <= g_maxy) {
                  this.attr({
                  transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [xSnap, ySnap]          
                });
              }  
        }else if (this.data("rotation") == 2){
              if ( obj0PointX + xSnap - objWidth >= 0 && 
                obj0PointX + xSnap  <= g_maxx  && 
                obj0PointY + ySnap >= 0 && 
                obj0PointY + ySnap + objHeight <= g_maxy) {
                this.attr({
                transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [xSnap, ySnap]          
              });
            }  
        }else if (this.data("rotation") == 3){
                if ( obj0PointX + xSnap - objWidth >= 0 && 
                obj0PointX + xSnap  <= g_maxx  && 
                obj0PointY + ySnap  - objHeight >= 0 && 
                obj0PointY + ySnap <= g_maxy) {
                this.attr({
                transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [xSnap, ySnap]          
              });
            }  
        }else {
            if ( obj0PointX + xSnap >= 0 && 
                obj0PointX + xSnap + objWidth <= g_maxx  && 
                obj0PointY + ySnap  - objHeight >= 0 && 
                obj0PointY + ySnap <= g_maxy) {
                this.attr({
                transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [xSnap, ySnap]          
              });
            }  
          }
        }
      }

      let stop = function() {
        content.disableScrolling = false

        if (content.multiSelection) {
          return;
        }
          content.checkAllowed();
          this.appendTo(this.s);
          this.data('origTransform',  this.transform().local);      
          this.data('ibb',  this.getBBox() );   
          this.data('rotation',  Number( this.attr("data-rotate")))

          content.saveElement(this)
      } 

      pElement.drag(move, start, stop );
      pElement.touchstart( start );
      pElement.touchmove( move );
      pElement.touchend( stop );
      pElement.attr({
        transform: pElement.transform().localMatrix
      });

    },
    addElement(obj){
      let lage = 1;
      let matrix = "matrix(1,0,0,1,0,0)";
      let sortMatrix = 'matrix(1,0,0,1,10,' + (obj.h - 10) + ')';
      if (obj.matrix) {
        matrix = obj.matrix;
      }
      if (obj.sort_matrix) {
        sortMatrix = obj.sort_matrix;
      }      
      if (obj.lage) {
        lage = obj.lage;
      }       
      let aktion = ''
      if (obj.drehen) {
        aktion =  ' data-allow-rotate="true" '
      }else if (obj.spiegeln) {
        aktion =  ' data-allow-mirror="true" '
      }   
      let fragment = window.Snap.parse('<g id="obj_' + obj.id + '"' + aktion + 'class="objekt"  transform="' + matrix + '" cidb-rf="' + obj.rf + '" data-width="' + obj.l + '"   data-rotate="' + lage + '" data-height="' + obj.h + '" data-id="' + obj.id + '" data-desc="<b>Element:</b> ' + obj.elementtyp + ', <b>Reihenfolge:</b> 460, <b>Bezeichnung:</b> ' + obj.bezeichnung + '"></g>');
      this.s.append(fragment)
      fragment = window.Snap.parse('<g class="element">' + obj.svg + '</g>');
      let ele = this.s.select('#obj_' + obj.id);
      ele.append(fragment);
      fragment = window.Snap.parse('<g class="sorting"  transform="' + sortMatrix + '" ><circle cx="0" cy="0" r="8" stroke="black" stroke-width="1" fill="#ffffff"></circle><text x="0" y="4" text-anchor="middle" style="font-family:Helvetica; font-weight:normal; font-size:9pt;" fill="#000000">' + obj.rf + '</text></g>');
      ele.append(fragment);
      this.addDrag(ele, this)
      return ele;
    }
  },
};
</script>
